import React, { memo, useEffect, useState } from "react";
import Loading from "../../common/Loading";
import ReactImageAnnotate from "react-image-annotate";
import { Button, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import * as keypointDefinition from "../../../keypointDefinition.js";
import makeImmutable from "seamless-immutable";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";

/**
 * 어노테이션 작업 페이지
 * @returns {JSX.Element}
 * @constructor
 */
const AnnotationImageAdmin = memo(
  ({ projectInfo, annotationService, workFolderInfo }) => {
    const history = useHistory();
    const params = useParams();

    //각종 param 상수
    const projectNo = projectInfo.projectNo, //프로젝트 PK
      isFin = workFolderInfo.isFin, //완료 / 미완료 구분
      annotationType = workFolderInfo.dataType, //작업유형 (OBJ,FL,OCR,...)
      workFolderNo = workFolderInfo.taskFolderNo, //작업폴더 정보 오브젝트
      jobStatus = workFolderInfo.status, //작업상태(작업/재작업/검수..)
      kpdef = keypointDefinition.Definitions, //키포인트 위치 고정상수
      tools =
        annotationType == "CAPTION" && isFin == true
          ? ["pan", "zoom", "저장", "나가기"]
          : isFin == true
          ? [
              "show-tags",
              "pan",
              // "create-ocr",
              "create-box",
              "create-minimal-box",
              // "create-polygon",
              // "create-blurbox",
              // "create-cuboid",
              // "create-polyline",
              // "create-keypoints",
              "copy",
              "저장",
              "나가기",
            ]
          : ["pan", "zoom", "나가기"],
      headApi = process.env.REACT_APP_BASE_URL + "/admin/images/",
      tokenInfo = annotationService.getHeaders(); //로드 이미지 주소.

    let sourceList = new Array();
    let rejectedObj = new Object(); // 재작업 시 반려한 목록 가이드

    let metaObj = {
      top_type: ["자켓", "셔츠", "점퍼", "티셔츠"],
      top_shape: ["긴팔(팔꿈치위)", "반팔(팔꿈치아래)", "민소매"],
      top_color: [
        "파랑",
        "검정",
        "갈색",
        "빨강",
        "주황",
        "노랑",
        "초록",
        "베이지",
        "보라",
        "분홍",
        "회색",
        "흰색",
        "남색",
        "체크",
        "스트라이프",
        "컬러블러킹",
        "프린팅",
      ],
      bottom_type: [
        "긴바지(무릎아래)",
        "반바지(무릎위)",
        "긴치마(무릎위)",
        "짧은치마(무릎위)",
      ],
      bottom_color: [
        "파랑",
        "검정",
        "갈색",
        "빨강",
        "주황",
        "노랑",
        "초록",
        "베이지",
        "보라",
        "분홍",
        "회색",
        "흰색",
        "남색",
        "패턴",
      ],
      hairstyle: [
        "모자",
        "헬멧",
        "긴머리(어깨선아래)",
        "짧은머리(귀나옴)",
        "단발머리(어깨선위)",
        "민머리",
        "묶은머리",
      ],
      glasses: ["착용", "안경미착용", "선글라스"],
      bag: ["긴끈가방", "짧은끈가방", "백팩", "캐리어", "가방미착용"],
      mask: ["마스크착용", "마스크미착용"],
      shoes: ["단화류(발목이하)", "부츠(발목이상)", "슬리퍼", "신발미착용"],
      "walking-accessory": [
        "휠체어",
        "목발",
        "지팡이",
        "노인보조기구",
        "기구없음",
      ],
      age_group: ["유아", "어린이", "청소년", "청장중년", "노년"],
      sex: ["남성", "여성"],
    };

    const [currentPage, setCurrentPage] = useState({
      idx: 0,
      image: {},
    });

    const [inputs, setInputs] = useState({
      isLoading: true,
      imageFileList: [],
      totalDataList: [],
      totalLength: 0,
      objectCodeList: [],
      KPObjectCodeList: [],
      tagList: [],
      metaColList: {},
      rejectCodeObj: {},
      rejectDesc: {},
    });

    const {
      isLoading,
      imageFileList,
      totalDataList,
      totalLength,
      objectCodeList,
      KPObjectCodeList,
      tagList,
      metaColList,
      rejectCodeObj,
      rejectDesc,
    } = inputs;

    //작업자용 데이터 파싱
    async function makeResJsonJob(resObj, idx) {
      let regi = undefined;
      let jsonData = resObj.jsonData;
      let keyObj = {};
      let typeCnt = {};
      let captions = [];
      let preLabelData = [];
      let preCaptionData = [];
      let lpPreLabelData = [];

      for await (let r of jsonData) {
        const {
          ["preLabelData"]: pre,
          ["labelData"]: labeldatas,
          ...keyData
        } = r;
        regi = r.labelData
          ? regi
            ? regi.concat(
                r.labelData &&
                  r.labelData.filter((r) => {
                    return r.type !== "caption";
                  })
              )
            : r.labelData &&
              r.labelData.filter((r) => {
                return r.type !== "caption";
              })
          : regi;
        captions = r.labelData
          ? captions
            ? captions.concat(
                r.labelData &&
                  r.labelData.filter((r) => {
                    return r.type == "caption";
                  })
              )
            : r.labelData &&
              r.labelData.filter((r) => {
                return r.type == "caption";
              })
          : captions;
        keyObj[keyData.dataType] = keyData.jsonDataNo;
        if (r.labelData && r.labelData.length > 0) {
          typeCnt[r.dataType] = r.labelData.length;
        }
      }

      let inspectCode = null;
      let inspectReason = null;
      let metaDescription = resObj.metaDescription
        ? resObj.metaDescription
        : {};

      let json = {
        key: keyObj,
        src: headApi + resObj.fileNo + "?taskFolderNo=" + workFolderNo,
        name: idx + 1,
        isViewed: false,
        regions: regi,
        preLabelData: preLabelData,
        lpPreLabelData: lpPreLabelData,
        preCaptionData: preCaptionData,
        typeCnt: typeCnt,
        metaDescription: metaDescription,
        inspectCode: inspectCode,
        inspectReason: inspectReason,
        captions: captions,
      };

      return json;
    }

    //검수자용 데이터 파싱
    async function makeResJsonInspect(resObj, idx) {
      let regi = [];
      let jsonData = resObj.jsonData;
      let keyObj = {};
      let typeCnt = {};
      let captions = [];

      if (jsonData[0].labelData != null) {
        for await (let r of jsonData) {
          regi = r.labelData
            ? regi
              ? regi.concat(
                  r.labelData &&
                    r.labelData.filter((r) => {
                      return r.type !== "caption";
                    })
                )
              : r.labelData &&
                r.labelData.filter((r) => {
                  return r.type == "caption";
                })
            : regi;
          captions = r.labelData
            ? captions
              ? captions.concat(
                  r.labelData &&
                    r.labelData.filter((r) => {
                      return r.type == "caption";
                    })
                )
              : r.labelData &&
                r.labelData.filter((r) => {
                  return r.type == "caption";
                })
            : captions;
          const { ["labelData"]: labeldatas, ...keyData } = r;
          if (r.labelData && r.labelData.length > 0) {
            typeCnt[r.dataType] = r.labelData.length;
          }
          keyObj[keyData.dataType] = keyData.jsonDataNo;
          for (let j = 0; j < regi.length; j++) {
            Object.assign(regi[j], { isInspect: true });
          }
          for (let j = 0; j < captions.length; j++) {
            Object.assign(captions[j], { isInspect: true });
          }
        }
      }

      let inspectCode = resObj.status ? resObj.rejectedCode : 1;
      let inspectReason = resObj.rejectedReason ? resObj.rejectedReason : "";
      let metaDescription = resObj.metaDescription
        ? resObj.metaDescription
        : {};
      let json = {
        key: keyObj,
        src: headApi + resObj.fileNo + "?taskFolderNo=" + workFolderNo,
        name: idx + 1,
        isViewed: resObj.status ? true : false,
        regions: regi,
        captions: captions,
        preLabelData: [],
        preCaptionData: [],
        lpPreLabelData: [],
        metaDescription: metaDescription,
        inspectCode: inspectCode,
        inspectReason: inspectReason,
      };
      if (inspectCode && inspectCode != "1") {
        rejectedObj[idx + 1] = [inspectCode, inspectReason];
      }

      return json;
    }

    //태그 / 반려사유 / 메타정보 / 라벨정보 설정
    async function setClassCodes(classCodes) {
      let tags = new Array(),
        rejects = new Object(),
        codes = new Array(),
        kpcodes = new Array(),
        metaz = new Object();

      for (let codeObj of classCodes) {
        switch (codeObj.dataType) {
          case "OBJ":
            codes.push(codeObj.className);
            break;
          case "REJECT":
            rejects[codeObj.classNo] = codeObj.classDescription;
            break;
          case "TAG":
            tags.push(codeObj.className);
            break;
          case "SKE17":
            annotationType != "SKE24" && kpcodes.push(codeObj.className);
            break;
          case "SKE24":
            annotationType == "SKE24" && kpcodes.push(codeObj.className);
            break;
          case "META":
            // metaz[codeObj.className] = codeObj.classDescription.split("|");
            metaz = metaObj;
            break;
          default:
            break;
        }
      }
      codes = codes.concat(["얼굴", "번호판"]);
      const set = new Set(codes);

      const classObj = {
        tagList: tags,
        objectCodeList: [...set],
        KPObjectCodeList: [...kpcodes, "키포인트"],
        rejectCodeObj: jobStatus != "1" && isFin == false ? rejects : {},
        metaColList: metaz,
      };

      return classObj;
    }

    //init
    useEffect(() => {
      annotationService
        .getAdminFolderImages(workFolderNo, annotationType)
        .then(async function (res) {
          //console.log(workFolderInfo)
          //console.log(res)
          const images = res.images;
          const classCodes = res.classCodes;
          const classObj = await setClassCodes(classCodes);

          if (isFin) {
            for await (let [idx, resObj] of images.entries()) {
              const json = await makeResJsonJob(resObj, idx);
              json && sourceList.push(json);
            }
          } else if (isFin == false) {
            for await (let [idx, resObj] of images.entries()) {
              const json = await makeResJsonInspect(resObj, idx);
              json && sourceList.push(json);
            }
          }
          //console.log(sourceList);

          //결과 없으면 일단 내보내자.
          if (sourceList.length == 0) {
            alert("이미지 로드 중 오류가 발생했습니다.");
            history.go(-1);
            return false;
          }

          let loadImage = makeImmutable.asMutable(sourceList[0]);
          if (!loadImage.blobUrl) {
            await axios
              .get(loadImage.src, {
                headers: tokenInfo,
                responseType: "arraybuffer",
              })
              .then((res) => {
                let blob = new Blob([res.data], { type: "image/png" });
                const url = window.URL.createObjectURL(blob);
                loadImage.blobUrl = url;
              });
          }

          setCurrentPage({
            idx: 0,
            image: [loadImage],
          });
          setInputs({
            ...inputs,
            ...classObj,
            totalDataList: sourceList,
            totalLength: sourceList.length,
            rejectDesc: rejectedObj,
            isLoading: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }, [annotationService]);

    const AnnotationTool = ReactImageAnnotate;
    return (
      <>
        {isLoading ? (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton
                variant="text"
                width={300}
                height={50}
                style={{ marginLeft: "20px" }}
              />
              <Skeleton
                variant="rect"
                width={160}
                height={38}
                style={{ marginRight: "140px", marginTop: "5px" }}
              />
              <Skeleton
                variant="rect"
                width={200}
                height={38}
                style={{ marginTop: "5px" }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="rect"
                width={20}
                height={650}
                style={{ marginLeft: "15px" }}
              />
              <Skeleton
                variant="rect"
                width={1350}
                height={780}
                style={{ marginLeft: "15px" }}
              />
              <Skeleton
                variant="rect"
                width={400}
                height={780}
                style={{ marginLeft: "5px" }}
              />
            </div>
          </div>
        ) : (
          <>
            <AnnotationTool
              workType={isFin == true ? "adminFin" : "adminPrg"}
              labelImages
              enabledTools={tools}
              regionClsList={objectCodeList}
              kpClsList={KPObjectCodeList}
              regionTagList={tagList}
              keypointDefinitions={kpdef}
              metaDataColumn={metaColList}
              rejectedDescription={rejectDesc}
              rejectCodeObj={rejectCodeObj}
              images={currentPage.image}
              totalLength={totalLength}
              selectedImage={0}
              jobInfo={workFolderInfo}
              tokenInfo={tokenInfo}
              currentIdx={currentPage.idx}
              onPageChange={async (prevPageData, prevPageIdx, nextPageIdx) => {
                //console.log(prevPageData);
                let tempData = makeImmutable.asMutable(totalDataList);
                let nextData = makeImmutable.asMutable(tempData[nextPageIdx]);
                if (!nextData.blobUrl) {
                  await axios
                    .get(nextData.src, {
                      headers: tokenInfo,
                      responseType: "arraybuffer",
                    })
                    .then((res) => {
                      let blob = new Blob([res.data], { type: "image/png" });
                      const url = window.URL.createObjectURL(blob);
                      nextData.blobUrl = url;
                    });
                }
                setCurrentPage({
                  idx: nextPageIdx,
                  image: [nextData],
                });
                tempData.splice(prevPageIdx, 1, prevPageData);
                setInputs({
                  ...inputs,
                  totalDataList: tempData,
                });
              }}
              onExit={(args) => {
                history.go(-1);
              }}
              onSave={(args) => {
                //저장(제출) 버튼 클릭 시 json 데이터 제출
                //console.log(args);

                //분리한 데이터 재통합
                let tempData = makeImmutable.asMutable(totalDataList);
                tempData.splice(currentPage.idx, 1, args.images[0]);

                //전송 리스트 선언
                let jsonList = new Array();
                let reqList = new Array();

                //전송 데이터 파싱 (FL 과 FL 아닌 경우로 구분)
                if (annotationType == "FL") {
                  let lpJsonList = new Array();
                  for (let data of tempData) {
                    if (!data.isViewed) continue;
                    let labelData =
                      data.regions == undefined ? [] : data.regions;
                    let faceLabelData = [];
                    let lpLabelData = [];
                    labelData.forEach((element, idx) => {
                      element.cls === "번호판"
                        ? lpLabelData.push(element)
                        : faceLabelData.push(element);
                    });
                    var json = {
                      jsonDataNo: data.key["FACE"],
                      labelData: faceLabelData,
                      metaDescription: data.metaDescription
                        ? data.metaDescription
                        : {},
                      labelCnt: faceLabelData.length,
                    };
                    var lpJson = {
                      jsonDataNo: data.key["LP"],
                      labelData: lpLabelData,
                      metaDescription: data.metaDescription
                        ? data.metaDescription
                        : {},
                      labelCnt: lpLabelData.length,
                    };
                    //검수 시 한정
                    jsonList.push(json);
                    lpJsonList.push(lpJson);
                  }
                  reqList.push(lpJsonList);
                } else {
                  for (let data of tempData) {
                    //미열람 데이터는 저장 안함
                    if (!data.isViewed) continue;
                    let labelData =
                      data.regions == undefined ? [] : data.regions;
                    let captionData =
                      data.captions == undefined ? [] : data.captions;
                    let json = {
                      jsonDataNo: data.key[annotationType],
                      labelData: labelData.concat(captionData),
                      metaDescription: data.metaDescription
                        ? data.metaDescription
                        : {},
                      labelCnt: labelData.length,
                    };
                    jsonList.push(json);
                  }
                }
                reqList.push(jsonList);
                //console.log("====보내는값:",workFolderNo)
                //console.log(reqList);

                //작업 전송
                if (isFin) {
                  annotationService
                    .postAdminJson(reqList, workFolderNo)
                    .then(function (res) {
                      notification["success"]({
                        message: "작업 정보 저장 완료",
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                      notification["error"]({
                        message: "저장에 실패하였습니다.",
                      });
                    });
                }
              }}
            />
          </>
        )}
      </>
    );
  }
);

export default AnnotationImageAdmin;
