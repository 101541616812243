import React, { useEffect, memo, useState } from "react";
import { PageHeader, Input, Table, Tag, Tabs } from "antd";
import isEqual from "lodash/isEqual";
import moment from "moment";
import "moment/locale/ko";

/**
 * 내 프로젝트
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectStat = ({ projectService }) => {
  const [isLoading, setLoading] = useState(true);
  const [projectStat, setProjectStat] = useState([]);
  const [projectSearchList, setProjectSearch] = useState();
  const [role, setRole] = useState("WORKER");

  const onSearch = (searchVal) => {
    const result = projectStat.filter((data) => {
      //return data.title.indexOf(searchVal) != -1 || data.taskName.indexOf(searchVal) != -1
      return (
        new RegExp(searchVal, "i").test(data.title) ||
        new RegExp(searchVal, "i").test(data.taskName)
      );
    });
    setProjectSearch(result);
    setLoading(false);
  };

  const { TabPane } = Tabs;

  useEffect(() => {
    projectService.getProjectStatList(role).then((r) => {
      setProjectStat(r);
      setLoading(false);
    });
  }, [role]);

  const changeTab = (key) => {
    setRole(key);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "rowNum",
      key: "rowNum",
      width: "5%",
      align: "center",
    },

    {
      title: "프로젝트 제목",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => {
        return a.title < b.title ? -1 : a == b ? 0 : 1;
      },
      width: "20%",
    },
    {
      title: "작업 이름",
      dataIndex: "taskName",
      key: "taskName",
      width: "15%",
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "프로젝트 기간",
      width: "11%",
      sorter: (a, b) => {
        const aa = moment(a.startDate);
        const bb = moment(b.startDate);
        return aa.diff(bb, "days");
      },
      render: (value) => {
        return (
          <>
            {value.startDate}~{value.endDate}
          </>
        );
      },
    },
    {
      title: "파일유형",
      dataIndex: "fileType",
      key: "fileType",
      width: "6%",
      render: (value) => {
        return (
          <Tag color={"cyan"} key={value} style={{ fontSize: "14px" }}>
            {value}
          </Tag>
        );
      },
    },
    {
      title: "계산방식",
      dataIndex: "unitType",
      key: "unitType",
      width: "6%",
      render: (value) => {
        if (value == "LABEL") {
          return (
            <Tag color={"purple"} key={value} style={{ fontSize: "14px" }}>
              라벨
            </Tag>
          );
        } else {
          return (
            <Tag color={"orange"} key={value} style={{ fontSize: "14px" }}>
              이미지
            </Tag>
          );
        }
      },
    },
    role === "WORKER"
      ? {
          title: "작업 단가",
          dataIndex: "workUnitPrice",
          key: "workUnitPrice",
          width: "7%",
          render: (value) => {
            const payPrice = value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return <>{payPrice}원</>;
          },
        }
      : {
          title: "검수 단가",
          dataIndex: "inspUnitPrice",
          key: "inspUnitPrice",
          width: "7%",
          render: (value) => {
            const payPrice = value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return <>{payPrice}원</>;
          },
        },
    {
      title: "이미지(프레임) 수",
      dataIndex: "fileCount",
      key: "fileCount",
      width: "9%",
    },
    {
      title: "작업 라벨 수",
      dataIndex: "workLabelCount",
      key: "workLabelCount",
      width: "7%",
    },
    // {
    //     title: '정산 금액',
    //     dataIndex: 'payment',
    //     key: 'payment',
    //     width: "8%",
    //     render: (value) =>{
    //         const pay = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //         return(<>{pay}원</>)
    //     }
    // },
    // {
    //     title: '정산상태',
    //     dataIndex: 'accountStatus',
    //     key: 'accountStatus',
    //     width: "8%",
    //     sorter: (a, b) => {
    //         return ( b.accountStatus < a.accountStatus ) ? -1 : ( a == b ) ? 0 : 1;
    //     },
    //     render: (value, record) => {
    //         if(value == "0"){
    //             return (
    //                 <Tag color={"red"} key={value} style={{ fontSize: '14px'}}>
    //                     예정
    //                 </Tag>
    //             );
    //         } else {
    //             return (
    //                 <Tag color={"blue"} key={value} style={{ fontSize: '14px'}}>
    //                     완료
    //                 </Tag>
    //             );
    //         }
    //     }
    // },
  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="내 프로젝트"
        subTitle={[
          <Tabs
            defaultActiveKey="WORKER"
            onChange={(key) => changeTab(key)}
            style={{ paddingLeft: "30px" }}
          >
            <TabPane tab="작업내역" key="WORKER"></TabPane>
            <TabPane tab="검수내역" key="INSPECTOR"></TabPane>
          </Tabs>,
        ]}
        extra={[
          <Input.Search
            placeholder="제목 및 작업명 검색"
            allowClear
            enterButton="검색"
            size="large"
            onSearch={onSearch}
          />,
        ]}
      />
      <Table
        columns={columns}
        dataSource={projectSearchList ? projectSearchList : projectStat}
        rowKey={(record) => record.projectNo}
        showSorterTooltip={false}
        loading={isLoading}
      />
    </>
  );
};

export default memo(ProjectStat, function (prevProps, nextProps) {
  return isEqual(prevProps.role, nextProps.role);
});
