import React, {useEffect, useState} from 'react';
import {PageHeader, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import moment from 'moment'
import 'moment/locale/ko';

/**
 * 공지사항
 * @returns {JSX.Element}
 * @constructor
 */
const NoticePage = ({ articleService, projectInfo }) => {

    const [noticeList, setNoticeList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    let projectNo = projectInfo.projectNo

    useEffect(() => {
        articleService.getNoticeList(projectNo).then(r => {
            console.log(r);
            setNoticeList(r);
            setLoading(false);
        });

    },[articleService]);

    const columns = [
        {
            title: 'No',
            dataIndex: 'articleId',
            key: 'articleId',
            width: "5%",
            align: "center"
        },

        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
            width: "20px",
            render : (text, record) => (
                <Link to={`/notice/${record.articleId}`}>{text}</Link>
            )
        },

        {
            title: '작성일',
            dataIndex: 'regDate',
            key: 'regDate',
            width: "10%",
            align: "center",
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm")
        }
    ];

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="공지사항"
            />
            <Table columns={columns} dataSource={noticeList} rowKey={record => record.articleId} loading={isLoading} />
        </>
    );
};

export default NoticePage;
