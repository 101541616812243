import React, {useEffect, useState} from 'react';
import {PageHeader, Table, Button} from "antd";
import {Link, useHistory} from 'react-router-dom';
import moment from 'moment'
import 'moment/locale/ko';

/**
 * 묻고 답하기
 * @returns {JSX.Element}
 * @constructor
 */
const QnaList = ({articleService, projectInfo}) => {

    let project = projectInfo;
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [qnaList, setQnaList] = useState([]);

    useEffect(() => {
        articleService.getQnaList(project.projectNo).then(res => {
            console.log(res);
            setQnaList(res);
            setLoading(false);
        })
    },[articleService]);

    const handleQnaWrite = () =>{
        history.push(`/qnaWrite`);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'questionId',
            key: 'questionId',
            width: "5%",
            align: "center"
        },

        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
            width: "50%",
            render : (text, record) => (
                <Link to={`/qna/${record.questionId}`}>{text}</Link>
            )
        },
        {
            title: '작성자',
            dataIndex: 'regId',
            key: 'regId',
            width: "5%",
            align: "center"
        },
        {
            title: '작성일',
            dataIndex: 'regDate',
            key: 'regDate',
            width: "8%",
            align: "center",
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm")
        },
        {
            title: '답변수',
            dataIndex: 'answerCount',
            key: 'answerCount',
            width: "5%",
            align: "center"
        }
    ];


    return (
        <>
            <PageHeader
                className="site-page-header"
                title="묻고 답하기"
                extra={[
                    <Button key="3" onClick={() => handleQnaWrite()}>질문 등록</Button>
                  ]}
            />
            <Table columns={columns} dataSource={qnaList} rowKey={record => record.articleId} loading={isLoading}/>
        </>
    );
};

export default QnaList;
