export default class AnnotationService {
    constructor(http, tokenStorage) {
        this.http = http;
        this.tokenStorage = tokenStorage;
    }

    async getFolderImages(taskFolderNo,type){
        return this.http.axios(`/images?dataType=${type}&taskFolderNo=${taskFolderNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    async getAdminFolderImages(taskFolderNo,type){
        return this.http.axios(`/admin/images?taskFolderNo=${taskFolderNo}&dataType=${type}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    async postJobJson(reqList,taskFolderNo) {
        let rawData = new Array();
        for(let i=0; i<reqList.length; i++){
            rawData.push({ "jsonDataList": reqList[i] })
        }
        return this.http.axios(`/images/work?taskFolderNo=${taskFolderNo}`, {
            method: 'PATCH',
            data: rawData,
            headers: this.getHeaders(),
        });
    }

    async postInspectJson(reqList,taskFolderNo) {
        let rawData = new Array();
        for(let i=0; i<reqList.length; i++){
            rawData.push({ "jsonDataList": reqList[i] })
        }
        return this.http.axios(`/images/inspect?taskFolderNo=${taskFolderNo}`, {
            method: 'PATCH',
            data: rawData,
            headers: this.getHeaders(),
        });
    }

    async postAdminJson(reqList,taskFolderNo) {
        let rawData = new Array();
        for(let i=0; i<reqList.length; i++){
            rawData.push({ "jsonDataList": reqList[i] })
        }
        return this.http.axios(`/admin/images?taskFolderNo=${taskFolderNo}`, {
            method: 'PATCH',
            data: rawData,
            headers: this.getHeaders(),
        });
        
    }

    getHeaders() {
        const token = this.tokenStorage.getToken();
        return {
            Authorization: `Bearer ${token}`,
        };
    }

}
