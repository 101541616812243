import React, {useEffect, useState} from 'react';
import {message, PageHeader, Table, Tag, Button} from "antd";
import {useHistory} from "react-router-dom";
import { 
    Dialog, 
    DialogActions, 
    DialogTitle,
    DialogContent,
    DialogContentText
} from "@material-ui/core";

            
/**
 * 내 완료된 작업
 * @returns {JSX.Element}
 * @constructor
 */
const JobCompleteList = ({getWorkFolderInfo, projectService, projectInfo}) => {

    const [myCompleteJob, setMyCompleteJob] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [taskValue, setTaskValue] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    let projectNo = projectInfo.projectNo;

    useEffect(() => {
        projectService.getMyCompleteJob(projectNo).then(r => {
            console.log(r);
            setMyCompleteJob(r);
            setLoading(false);
        })

    },[projectService]);

    const handleClickOpen = (value) => {
        setOpen(true);
        setTaskValue(value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //작업 완료
    const onJobComplete = (taskFolderNo, role) => {
        handleClose();
        projectService.onJobEnd(projectNo,taskFolderNo, role)
        .then(r =>{
            message.info("재검수 요청 처리되었습니다.");
            history.push('/jobCompleteList');
        })
        .catch(error => {
            console.error(error);
        });

    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'rowNum',
            key: 'rowNum',
            width: "5%",
            align : 'center',
        },

        {
            title: '작업명',
            dataIndex: 'taskName',
            key: 'taskName',
            width: "15%",
        },
        {
            title: '데이터명',
            width: "16%",
            render: (value) => {
                if(value.fileType == "VIDEO"){
                    return (<>{value.videoName}</>);
                } else {
                    return (<>{value.folderName}</>);
                }
            }
        },

        {
            title: 'AI 선가공 유형',
            dataIndex: 'dataType',
            key: 'dataType',
            width: "7%",
            render: (value) => {
                let tag = "오류";
                let color = "red";
                switch (value) {
                    case "FACE":
                        tag = "비식별화(얼굴)";
                        color = "geekblue";
                        break;
                    case "OBJ":
                        tag = "객체인식";
                        color = "green";
                        break;
                    case "SKE17":
                        tag = "스켈레톤17";
                        color = "purple";
                        break;
                    case "SKE24":
                        tag = "스켈레톤24";
                        color = "purple";
                        break;
                    case "OCR":
                        tag = "OCR";
                        color = "orange";
                        break;
                    case "LP":
                        tag = "비식별화(번호판)";
                        color = "orange";
                        break;
                    case "FL":
                        tag = "비식별화";
                        color = "orange";
                        break;
                    case "CAPTION":
                        tag = "캡셔닝";
                        color = "green";
                        break; 
                    default:
                        tag = "선가공 없음";
                        color = "darkgray";
                        break;
                }
                return (
                    <Tag color={color} style={{ fontSize: '14px'}}>
                        {tag}
                    </Tag>
                );
            }
        },

        {
            title: '파일유형',
            dataIndex: 'fileType',
            key: 'fileType',
            width: "7%",
            render: (value) => {
                return (
                    <Tag color={"cyan"} key={value} style={{ fontSize: '14px'}}>
                        {value}
                    </Tag>
                );
            }
        },

        {
            title: '상태',
            dataIndex: 'status',
            key: 'status',
            width: "9%",
            render : (value) => {
                let stat = null;
                switch (value) {
                    case '1':
                        stat = "작업 중";
                        break;
                    case '2':
                        stat = "작업 완료";
                        break;
                    case '3':
                        stat = "검수 중";
                        break;
                    case '4':
                        stat = "검수 완료";
                        break;
                    case '5':
                        stat = "재작업 요청";
                        break;
                    case '6':
                        stat = "재검수 대기";
                        break;
                    default:
                        stat = "error";
                }
                return (
                    <>
                    {stat}
                    </>
                );
            }
        },

        {
            title: '전체 파일 수',
            dataIndex: 'fileCount',
            key: 'fileCount',
            width: "9%",
        },

        {
            title: '검수 완료 수',
            dataIndex: 'firstInspectionCount',
            key: 'firstInspectionCount',
            width: "9%",
        },

        {
            title: '반려수',
            dataIndex: 'rejectedCount',
            key: 'rejectedCount',
            width: "5%",
        }
    ];

    return (
        <>
            {/*내 완료된 작업 : USER_ID 가 자신이며 완료된 TASK들을 리스트로 출력하는 페이지.
            입력값 : project_no
            출력값 :
            TASK_NO(정렬기준) ,  task_name, type, 전체 파일개수(task_file 테이블에서), 1차검수 통과수, 2차검수 통과수, 반려수 REG_DATE*/}
            <PageHeader
                className="site-page-header"
                title="내 완료된 작업"
            />
            <Table columns={columns} dataSource={myCompleteJob} rowKey={record => record.taskFolderNo} loading={isLoading} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {taskValue.taskName} - {taskValue.folderName} 작업을 재검수 요청 하시겠습니까?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        취소
                </Button>
                    <Button onClick={() => onJobComplete(taskValue.taskFolderNo, taskValue.role)} color="primary" autoFocus>
                        확인
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default JobCompleteList;
