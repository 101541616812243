import * as muiColors from "@material-ui/core/colors";
import React, { memo, useEffect, useState } from "react";
import Loading from "../../common/Loading";
import ReactImageAnnotate from "react-image-annotate";
import { Button, notification } from "antd";
import { useHistory } from "react-router-dom";
import makeImmutable from "seamless-immutable";
import * as keypointDefinition from "../../../keypointDefinition.js";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _objectSpread from "@babel/runtime/helpers/esm/objectSpread";

/**
 * 어노테이션 작업 페이지
 * @returns {JSX.Element}
 * @constructor
 */
const AnnotationVideoJob = memo(
  ({ projectInfo, annotationService, workFolderInfo }) => {
    const history = useHistory();

    //각종 param 상수
    const projectNo = projectInfo.projectNo, //프로젝트 PK
      role = workFolderInfo.role, //작업자/검수자 구분
      annotationType = workFolderInfo.dataType, //작업유형 (OBJ,FL,OCR,...)
      workFolderNo = workFolderInfo.taskFolderNo, //작업폴더 정보 오브젝트
      jobStatus = workFolderInfo.status, //작업상태(작업/재작업/검수..)
      kpdef = keypointDefinition.Definitions, //키포인트 위치 고정상수
      tools =
        role == "WORKER"
          ? ["show-tags", "pan", "create-blurbox", "copy", "저장", "나가기"]
          : ["pan", "zoom", "저장", "나가기"],
      headApi = process.env.REACT_APP_BASE_URL + "/images/", //headAPi 주소. 최상위에 .env 파일을 작성하여 지정할 것.
      //REACT_APP_BASE_URL=http://at-web.vitasoft.co.kr/backend/v1/api
      tokenInfo = annotationService.getHeaders(); //로드 이미지 주소.

    let resetLabelFlag =
      role == "WORKER" && localStorage.getItem("resetLabelFlag")
        ? localStorage.getItem("resetLabelFlag")
        : true;
    let sourceList = new Array();
    let rejectedObj = new Object(); // 재작업 시 반려한 목록 가이드
    //비식별화 기준 선정용
    let facePersent = localStorage.getItem("settings_facePersent")
      ? parseInt(
          Number(
            localStorage
              .getItem("settings_facePersent")
              .replace(/[^0-9\.]+/g, "")
          )
        )
      : 80;
    let lpPersent = localStorage.getItem("settings_lpPersent")
      ? parseInt(
          Number(
            localStorage.getItem("settings_lpPersent").replace(/[^0-9\.]+/g, "")
          )
        )
      : 80;

    const [currentPage, setCurrentPage] = useState({
      idx: 0,
      image: {},
    });
    const [inputs, setInputs] = useState({
      isLoading: true,
      totalDataList: [],
      totalLength: 0,
      objectCodeList: [],
      KPObjectCodeList: [],
      tagList: [],
      metaColList: {},
      rejectCodeObj: {},
      rejectDesc: {},
    });

    const {
      isLoading,
      totalDataList,
      totalLength,
      tagList,
      metaColList,
      rejectCodeObj,
      rejectDesc,
    } = inputs;

    const [labeDesc, setLabeDesc] = useState([]);
    //const [classDesc, setClassDesc] = useState({}); 트랙킹. 보류중.

    //파싱용 박스 추출
    function getBox(landmarks) {
      var points = [];
      for (var i = 0; i < landmarks.length; i += 2)
        points.push(landmarks.slice(i, i + 2));

      var box = {
        x: Math.min.apply(
          Math,
          _toConsumableArray(
            points.map(function (_ref) {
              var _ref2 = _slicedToArray(_ref, 2),
                x = _ref2[0],
                y = _ref2[1];

              return x;
            })
          )
        ),
        y: Math.min.apply(
          Math,
          _toConsumableArray(
            points.map(function (_ref3) {
              var _ref4 = _slicedToArray(_ref3, 2),
                x = _ref4[0],
                y = _ref4[1];

              return y;
            })
          )
        ),
        w: 0,
        h: 0,
      };
      box.w =
        Math.max.apply(
          Math,
          _toConsumableArray(
            points.map(function (_ref5) {
              var _ref6 = _slicedToArray(_ref5, 2),
                x = _ref6[0],
                y = _ref6[1];

              return x;
            })
          )
        ) - box.x;
      box.h =
        Math.max.apply(
          Math,
          _toConsumableArray(
            points.map(function (_ref7) {
              var _ref8 = _slicedToArray(_ref7, 2),
                x = _ref8[0],
                y = _ref8[1];

              return y;
            })
          )
        ) - box.y;
      return box;
    }

    //작업자용 데이터 파싱
    async function makeResJsonJob(resObj, idx, nw, nh) {
      let regi = new Array();
      let jsonData = resObj.jsonData;
      let keyObj = {};
      let typeCnt = {};
      let preLabelArray = new Array();
      let lpPreLabelArray = new Array();

      for await (let r of jsonData) {
        if (
          // resetLabelFlag ||
          jobStatus == "5"
        ) {
          regi = r.labelData ? regi.concat(r.labelData) : regi;
          keyObj[r.dataType] = r.jsonDataNo;
          if (r.labelData && r.labelData.length > 0) {
            typeCnt[r.dataType] = r.labelData.length;
          }
        } else {
          if (resetLabelFlag) {
            regi = r.labelData ? regi.concat(r.labelData) : regi;
          }

          const {
            ["preLabelData"]: pre,
            ["labelData"]: labeldatas,
            ["dataType"]: dataType,
            ...keyData
          } = r;
          keyObj[keyData.dataType] = keyData.jsonDataNo;
          if (Object.keys(r.preLabelData).length > 0) {
            for (const [key, value] of Object.entries(r.preLabelData)) {
              switch (key.toUpperCase()) {
                case "LP":
                  lpPreLabelArray =
                    r.dataType == "LP"
                      ? lpPreLabelArray.concat(value)
                      : lpPreLabelArray;
                  break;
                case "FACE":
                  preLabelArray =
                    r.dataType == "FACE"
                      ? preLabelArray.concat(value)
                      : preLabelArray;
                  break;
                default:
                  break;
              }
            }
          }

          //얼굴 쪽 라벨데이터 생성
          for (let preLabelData of preLabelArray) {
            let persent = facePersent;
            let typeName = "얼굴";

            //인식률 옵션값 미만은 제외함
            let per = preLabelData.conf
              ? Math.floor(preLabelData.conf * 100)
              : 100;
            if (per < persent || !preLabelData.landmarks) {
              continue;
            }

            var coor = preLabelData.coor;
            var box = getBox(preLabelData.landmarks);
            var labelx =
                (coor[0] + box.x) / (nw * 2) > 0
                  ? (coor[0] + box.x) / (nw * 2)
                  : 0,
              labely =
                (coor[1] + box.y) / (nh * 2) > 0
                  ? (coor[1] + box.y) / (nh * 2)
                  : 0,
              labelw =
                (box.w + (coor[2] - coor[0])) / (nw * 2) < nw
                  ? (box.w + (coor[2] - coor[0])) / (nw * 2)
                  : nw,
              labelh =
                (box.h + (coor[3] - coor[1])) / (nh * 2) < nh
                  ? (box.h + (coor[3] - coor[1])) / (nh * 2)
                  : nh;

            var labels = {
              type: "blurcircle",
              x: labelx,
              y: labely,
              w: labelw,
              h: labelh,
              highlighted: false,
              editingLabels: false,
              color: muiColors.amber[200],
              isBlur: true,
              id: Math.floor(Math.random() * 100000000000),
              cls: typeName,
              dots: per + "%",
            };
            regi.push(labels);
            //클래스에도 추가
            typeCnt[dataType] = typeCnt[dataType] ? typeCnt[dataType] + 1 : 1;
          }

          //번호판 쪽 라벨데이터 생성
          for (let preLabelData of lpPreLabelArray) {
            let persent = lpPersent;
            let typeName = "번호판";

            //인식률 옵션값 미만은 제외함
            let per = preLabelData.conf
              ? Math.floor(preLabelData.conf * 100)
              : 100;
            if (per < persent || !preLabelData.landmarks) {
              continue;
            }

            var coor = preLabelData.coor;

            var labelx = coor[0] / nw > 0 ? coor[0] / nw : 0,
              labely = coor[1] / nh > 0 ? coor[1] / nh : 0,
              labelw =
                (coor[2] - coor[0]) / nw < nw ? (coor[2] - coor[0]) / nw : nw,
              labelh =
                (coor[3] - coor[1]) / nh < nh ? (coor[3] - coor[1]) / nh : nh;

            var labels = {
              type: "blurcircle",
              x: labelx,
              y: labely,
              w: labelw,
              h: labelh,
              highlighted: false,
              editingLabels: false,
              color: muiColors.amber[200],
              isBlur: true,
              id: Math.floor(Math.random() * 100000000000),
              cls: typeName,
              dots: per + "%",
            };
            regi.push(labels);
            //클래스에도 추가
            typeCnt[dataType] = typeCnt[dataType] ? typeCnt[dataType] + 1 : 1;
          }
        }
      }

      //jsondata 이후
      let inspectCode = resObj.rejectedCode ? resObj.rejectedCode : null;
      let inspectReason = resObj.rejectedReason ? resObj.rejectedReason : null;
      let metaDescription = resObj.metaDescription
        ? resObj.metaDescription
        : {};
      let json = {
        key: keyObj,
        src: headApi + resObj.fileNo + "?taskFolderNo=" + workFolderNo,
        name: idx + 1,
        isViewed: true,
        lpPreLabelArray: regi == undefined ? lpPreLabelArray : [],
        preLabelArray: regi == undefined ? preLabelArray : [],
        regions: regi,
        typeCnt: typeCnt,
        metaDescription: metaDescription,
        inspectCode: inspectCode,
        inspectReason: inspectReason,
      };

      if (jobStatus == "5" && inspectCode && inspectCode != "1") {
        rejectedObj[idx + 1] = [inspectCode, inspectReason];
      }

      return json;
    }

    //태그 / 반려사유 / 메타정보 / 라벨정보 설정
    function setClassCodes(classCodes) {
      let tags = new Array(),
        rejects = new Object(),
        codes = new Array(),
        kpcodes = new Array(),
        metaz = new Object();

      for (let codeObj of classCodes) {
        switch (codeObj.dataType) {
          case "OBJ":
            codes.push(codeObj.className);
            break;
          case "REJECT":
            rejects[codeObj.classNo] = codeObj.classDescription;
            break;
          case "TAG":
            tags.push(codeObj.className);
            break;
          case "SKE17":
            annotationType != "SKE24" && kpcodes.push(codeObj.className);
            break;
          case "SKE24":
            annotationType == "SKE24" && kpcodes.push(codeObj.className);
            break;
          case "META":
            metaz[codeObj.className] = codeObj.classDescription.split("|");
            break;
          default:
            break;
        }
      }
      codes = codes.concat(["얼굴", "번호판"]);
      const set = new Set(codes);

      const classObj = {
        tagList: tags,
        objectCodeList: [...set],
        KPObjectCodeList: [...kpcodes, "키포인트"],
        rejectCodeObj: jobStatus != "1" ? rejects : {},
        metaColList: metaz,
      };

      return classObj;
    }

    //init
    useEffect(() => {
      annotationService
        .getFolderImages(workFolderNo, annotationType)
        .then(async (res) => {
          //console.log(res);
          const images = res.images;
          const classCodes = res.classCodes;
          const classObj = await setClassCodes(classCodes);
          //우측 표시용 변수 초기화
          let descList = new Array(), //검출 목록 텍스트들의 List
            startIdx = 0, //각 검출 목록의 시작 page
            labelLen = 0; //각 검출 목록의 등장 길이

          if (role == "WORKER") {
            //첫 이미지 로드하여 이미지 크기 계산
            let firstUrl =
              headApi + images[0].fileNo + "?taskFolderNo=" + workFolderNo;
            //이미지 API 오류 시 사용
            // mos.src = "https://file.mk.co.kr/meet/neds/2019/03/image_readtop_2019_183661_15536412633683938.jpg";
            let blobUrl = await axios
              .get(firstUrl, {
                headers: tokenInfo,
                responseType: "arraybuffer",
              })
              .then((res) => {
                let blob = new Blob([res.data], { type: "image/png" });
                const url = window.URL.createObjectURL(blob);
                return url;
              });
            let mos = new Image();
            mos.src = blobUrl;
            mos.onload = async function () {
              let nw = mos.naturalWidth;
              let nh = mos.naturalHeight;
              let prevTypeCnt = {};
              for await (let [idx, resObj] of images.entries()) {
                const json = await makeResJsonJob(resObj, idx, nw, nh);
                sourceList.push(json);
                //검출 목록 생성
                let tempTypeCnt = json.typeCnt;

                //이전 값과 typeCnt에 변화가 발생
                if (
                  JSON.stringify(tempTypeCnt) != JSON.stringify(prevTypeCnt)
                ) {
                  //이전 값이 빈 값이었음
                  if (Object.keys(prevTypeCnt).length === 0) {
                    startIdx = idx + 1;
                    labelLen = 0;
                  } else {
                    let endIdx = startIdx + labelLen;
                    let descText = "";
                    for (let [dataType, cnt] of Object.entries(prevTypeCnt)) {
                      descText =
                        descText +
                        (dataType == "FACE"
                          ? "얼굴"
                          : dataType == "LP"
                          ? "번호판"
                          : "기타") +
                        cnt +
                        "건 ";
                    }
                    let ranges =
                      labelLen == 0
                        ? startIdx.toString()
                        : startIdx.toString() + " ~ " + endIdx.toString();
                    let text = {
                      name: descText,
                      ranges: ranges,
                      startIdx: startIdx,
                      endIdx: endIdx,
                      cnt: endIdx - startIdx + 1,
                    };
                    descList.push(text);
                    if (Object.keys(tempTypeCnt).length !== 0) {
                      //0개 초과에서 0개 아닌 다른 개수로 변화함.
                      startIdx = idx + 1;
                    }
                  }
                  //변화로 인한 초기화
                  prevTypeCnt = tempTypeCnt;
                  labelLen = 0;
                } else {
                  //라벨 개수 유지 시
                  labelLen = labelLen + 1;
                }
                //마지막 페이지인 경우이며 prevTypeCnt에 뭔가 있음.
                if (
                  idx + 1 == images.length &&
                  Object.keys(prevTypeCnt).length !== 0
                ) {
                  let endIdx = startIdx + labelLen;
                  let descText = "";
                  for (let [dataType, cnt] of Object.entries(prevTypeCnt)) {
                    descText =
                      descText +
                      (dataType == "FACE"
                        ? "얼굴"
                        : dataType == "LP"
                        ? "번호판"
                        : "기타") +
                      cnt +
                      "건 ";
                  }
                  let ranges =
                    labelLen == 0
                      ? startIdx.toString()
                      : startIdx.toString() + " ~ " + endIdx.toString();
                  let text = {
                    name: descText,
                    ranges: ranges,
                    startIdx: startIdx,
                    endIdx: endIdx,
                    cnt: endIdx - startIdx + 1,
                  };
                  descList.push(text);
                }
              }

              //결과 없으면 일단 내보내자.
              if (sourceList.length == 0) {
                alert("이미지 로드 중 오류가 발생했습니다.");
                history.go(-1);
              }

              localStorage.setItem("resetLabelFlag", true);
              let loadImage = makeImmutable.asMutable(sourceList[0]);
              if (!loadImage.blobUrl) {
                await axios
                  .get(loadImage.src, {
                    headers: tokenInfo,
                    responseType: "arraybuffer",
                  })
                  .then((res) => {
                    let blob = new Blob([res.data], { type: "image/png" });
                    const url = window.URL.createObjectURL(blob);
                    loadImage.blobUrl = url;
                  });
              }

              setCurrentPage({
                idx: 0,
                image: [loadImage],
              });
              setLabeDesc(descList);
              setInputs({
                ...inputs,
                ...classObj,
                totalDataList: sourceList,
                totalLength: sourceList.length,
                rejectDesc: rejectedObj,
                isLoading: false,
              });
            };
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, [annotationService]);

    const AnnotationTool = ReactImageAnnotate;
    return (
      <>
        {isLoading ? (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton
                variant="text"
                width={300}
                height={50}
                style={{ marginLeft: "20px" }}
              />
              <Skeleton
                variant="rect"
                width={160}
                height={38}
                style={{ marginRight: "140px", marginTop: "5px" }}
              />
              <Skeleton
                variant="rect"
                width={200}
                height={38}
                style={{ marginTop: "5px" }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="rect"
                width={20}
                height={650}
                style={{ marginLeft: "15px" }}
              />
              <Skeleton
                variant="rect"
                width={1350}
                height={780}
                style={{ marginLeft: "15px" }}
              />
              <Skeleton
                variant="rect"
                width={400}
                height={780}
                style={{ marginLeft: "5px" }}
              />
            </div>
          </div>
        ) : (
          <>
            <AnnotationTool
              workType={role == "INSPECTOR" ? "inspect" : undefined}
              labelImages
              enabledTools={tools}
              regionClsList={[]}
              kpClsList={[]}
              regionTagList={tagList}
              keypointDefinitions={kpdef}
              metaDataColumn={metaColList}
              rejectedDescription={rejectDesc}
              rejectCodeObj={rejectCodeObj}
              images={currentPage.image}
              totalLength={totalLength}
              selectedImage={0}
              jobInfo={workFolderInfo}
              tokenInfo={tokenInfo}
              currentIdx={currentPage.idx}
              labelDescription={labeDesc}
              classDescription={{}}
              onPageChange={async (prevPageData, prevPageIdx, nextPageIdx) => {
                //console.log(prevPageData);
                let tempData = makeImmutable.asMutable(totalDataList);
                let nextData = makeImmutable.asMutable(tempData[nextPageIdx]);
                if (!nextData.blobUrl) {
                  await axios
                    .get(nextData.src, {
                      headers: tokenInfo,
                      responseType: "arraybuffer",
                    })
                    .then((res) => {
                      let blob = new Blob([res.data], { type: "image/png" });
                      const url = window.URL.createObjectURL(blob);
                      nextData.blobUrl = url;
                    });
                }
                setCurrentPage({
                  idx: nextPageIdx,
                  image: [nextData],
                });
                tempData.splice(prevPageIdx, 1, prevPageData);
                setInputs({
                  ...inputs,
                  totalDataList: tempData,
                });
              }}
              onExit={(args) => {
                history.push("/jobProgressList");
              }}
              onSave={(args) => {
                //저장(제출) 버튼 클릭 시 json 데이터 제출

                //분리한 데이터 재통합
                let tempData = makeImmutable.asMutable(totalDataList);
                tempData.splice(currentPage.idx, 1, args.images[0]);

                //전송 데이터 선언
                let jsonList = new Array();
                let reqList = new Array();

                if (annotationType == "FL") {
                  let lpJsonList = new Array();
                  for (let i = 0; i < tempData.length; i++) {
                    let labelData = tempData[i].regions;
                    let faceLabelData = [];
                    let lpLabelData = [];
                    labelData.forEach((element, idx) => {
                      element.cls === "번호판"
                        ? lpLabelData.push(element)
                        : faceLabelData.push(element);
                    });
                    var json = {
                      jsonDataNo: tempData[i].key["FACE"],
                      labelData: faceLabelData,
                      metaDescription: tempData[i].metaDescription
                        ? tempData[i].metaDescription
                        : {},
                      labelCnt: faceLabelData.length,
                    };
                    jsonList.push(json);
                    var lpJson = {
                      jsonDataNo: tempData[i].key["LP"],
                      labelData: lpLabelData,
                      metaDescription: tempData[i].metaDescription
                        ? tempData[i].metaDescription
                        : {},
                      labelCnt: lpLabelData.length,
                    };
                    lpJsonList.push(lpJson);
                  }
                  reqList.push(lpJsonList);
                } else {
                  for (let i = 0; i < tempData.length; i++) {
                    var json = {
                      jsonDataNo: tempData[i].key[annotationType],
                      labelData: tempData[i].regions,
                      metaDescription: tempData[i].metaDescription
                        ? tempData[i].metaDescription
                        : {},
                      labelCnt: tempData[i].regions.length,
                    };
                    jsonList.push(json);
                  }
                }

                reqList.push(jsonList);
                //console.log(reqList);
                annotationService
                  .postJobJson(reqList, workFolderNo)
                  .then(function (res) {
                    notification["success"]({
                      message: "작업 정보 저장 완료",
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                    notification["error"]({
                      message: "저장에 실패하였습니다.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
  }
);

export default AnnotationVideoJob;
