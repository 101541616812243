import React, {memo, useEffect, useState} from 'react';
import {Button, PageHeader, Table, Tag, message} from "antd";
import {useHistory} from "react-router-dom";
import moment from 'moment'
import 'moment/locale/ko';
import { 
    Dialog, 
    DialogActions, 
    DialogTitle,
    DialogContent,
    DialogContentText
} from "@material-ui/core";

/**
 * 어노테이션 작업 목록
 * @returns {JSX.Element}
 * @constructor
 */
const JobTrgtList = memo(({ projectService, projectInfo, getWorkFolderInfo }) => {
    let projectNo = projectInfo.projectNo;

    const [open, setOpen] = React.useState(false);
    const [isLoading, setLoading]  = useState(true);
    const [taskValue, setTaskValue] = React.useState([]);
    const [annotationList, setAnnotations] = useState([]);

    const history = useHistory();

    useEffect(() => {
        projectService.getAnnotationList(projectNo).then(res => {
            console.log(res);
            setAnnotations(res);
            setLoading(false);
        }).catch(error => {
            message.error(error.message);
        });

    },[projectService]);

    const onJobStart = (value) => {

        projectService.onJobStart(projectNo,value.taskFolderNo,value.role).then(r => {
            getWorkFolderInfo(value);
            if(value.fileType=="VIDEO"){
                history.push('/annotationVideoJob');
            } else {
                history.push('/annotationImage/all');
            } 
        }).catch(error => {
            message.error(error.message);
        });
    }

    const handleClickOpen = (value) => {
        setTaskValue(value);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    const columns = [
        {
            title: 'No',
            dataIndex: 'rowNum',
            key: 'rowNum',
            width: '5%',
            align : 'center'
        },
        {
            title: '작업명',
            dataIndex: 'taskName',
            key: 'taskName',
            width: '20%',
        },
        {
            title: '데이터명',
            width: "20%",
            render: (value) => {
                if(value.fileType == "VIDEO"){
                    return (<>{value.videoName}</>);
                } else {
                    return (<>{value.folderName}</>);
                }
            }
        },
        {
            title: 'AI 선가공 유형',
            dataIndex: 'dataType',
            key: 'dataType',
            width: "10%",
            render: (value) => {
                let tag = "오류";
                let color = "red";
                switch (value) {
                    case "FACE":
                        tag = "비식별화(얼굴)";
                        color = "geekblue";
                        break;
                    case "OBJ":
                        tag = "객체인식";
                        color = "green";
                        break;
                    case "SKE17":
                        tag = "스켈레톤17";
                        color = "purple";
                        break;
                    case "SKE24":
                        tag = "스켈레톤24";
                        color = "purple";
                        break;
                    case "OCR":
                        tag = "OCR";
                        color = "orange";
                        break;
                    case "LP":
                        tag = "비식별화(번호판)";
                        color = "orange";
                        break;
                    case "FL":
                        tag = "비식별화";
                        color = "orange";
                        break;
                    case "CAPTION":
                        tag = "캡셔닝";
                        color = "green"   
                        break;  
                    default:
                        tag = "선가공 없음";
                        color = "darkgray";
                        break;
                }
                return (
                    <Tag color={color} style={{ fontSize: '14px'}}>
                        {tag}
                    </Tag>
                );
            }
        },

        {
            title: '파일유형',
            dataIndex: 'fileType',
            key: 'fileType',
            width: "8%",
            render: (value) => {
                return (
                    <Tag color={"cyan"} key={value} style={{ fontSize: '14px'}}>
                        {value}
                    </Tag>
                );
            }
        },
        {
            title: '전체 파일 수',
            dataIndex: 'fileCount',
            key: 'fileCount',
            width: '9%',
        },
        {
            title: '생성일',
            dataIndex: 'regDate',
            key: 'regDate',
            width: "10%",
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm")
        },
        {
            title: '시작',
            width: "10%",
            render: (value) => {
                return (
                    <>
                        <Button type="primary" onClick={() => handleClickOpen(value)}>작업 시작</Button>
                    </>
                )
            }
        }
    ]


    return (
        <>
            {/*어노테이션 작업 목록 : USER_ID 가 배정되지 않은 TASK들을 리스트로 출력하는 페이지.
            입력값 : project_no
            출력값 :
            TASK_NO(정렬기준) ,  task_name, type, 파일개수(task_file 테이블에서), REG_DATE*/}

            <PageHeader
                className="site-page-header"
                title="어노테이션 작업 목록"
            />
            <Table columns={columns} dataSource={annotationList} rowKey={record => record.taskFolderNo} loading={isLoading} showSorterTooltip={false} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {taskValue.taskName} 작업을 시작하시겠습니까?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        취소
                </Button>
                    <Button onClick={() => onJobStart(taskValue)} color="primary" autoFocus>
                        확인
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default memo(JobTrgtList);
